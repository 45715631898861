import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "ma-3", attrs: { align: "center", justify: "center" } },
        [
          _c(
            VCard,
            { attrs: { align: "left", justify: "left" } },
            [
              _c(
                VCardText,
                { staticClass: "pa-3 font-weight-light white black--text" },
                [
                  _c(VIcon, { staticClass: "primary--text pa-2" }, [
                    _vm._v(" mdi-handshake-outline ")
                  ]),
                  _vm._v("Renegociação > Listar Renegociações ativas ")
                ],
                1
              )
            ],
            1
          ),
          _c(
            VCard,
            {
              staticClass: "my-3 pa-3",
              staticStyle: { "border-radius": "20px" },
              attrs: { color: "primary" }
            },
            [
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    {
                      staticClass: "ma-3 pa-3",
                      attrs: { cols: "3", xs: "3", md: "3" }
                    },
                    [
                      _c(
                        VIcon,
                        {
                          staticClass: "white--text ma-6 pa-6 mx-auto my-auto",
                          attrs: { size: "110", "elevation-8": "" }
                        },
                        [_vm._v(" mdi-handshake-outline ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      staticClass: "ma-3 pa-3 mx-auto my-auto",
                      attrs: {
                        cols: "12",
                        sm: "12",
                        md: "5",
                        align: "left",
                        justify: "left"
                      }
                    },
                    [
                      _c(
                        VCardText,
                        { staticClass: "white--text display-1" },
                        [
                          _vm._v(" Gerenciar "),
                          _c("strong", [_vm._v("Renegociações")]),
                          _vm._v(" de Títulos "),
                          _c(
                            VChip,
                            {
                              staticClass: "ma-2",
                              attrs: {
                                color: "secondary",
                                label: "",
                                "text-color": "white"
                              }
                            },
                            [
                              _c(VIcon, { attrs: { left: "" } }, [
                                _vm._v(" mdi-label ")
                              ]),
                              _vm._v(" Novidade! ")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      staticClass: "ma-3 pa-3 mx-auto my-auto",
                      attrs: {
                        sm: "12",
                        md: "2",
                        align: "left",
                        justify: "left"
                      }
                    },
                    [
                      _c(
                        VCardText,
                        {
                          staticClass:
                            "white--text font-weight-light subtitle mb-3 pa-3"
                        },
                        [
                          _vm._v(
                            " Gerencie renegociações de pagamentos realizados através da Plataforma Protestado "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                {
                  staticClass: "mt-sm-n8 ma-6",
                  attrs: { "elevation-0": "", outlined: "" }
                },
                [
                  _c(
                    VExpansionPanels,
                    [
                      _c(
                        VExpansionPanel,
                        [
                          _c(
                            VExpansionPanelHeader,
                            { staticClass: "grey lighten-4" },
                            [
                              _c(
                                VCardText,
                                {
                                  staticClass: "font-weight-light black--text"
                                },
                                [
                                  _c(
                                    VIcon,
                                    { staticClass: "primary--text lighten-2" },
                                    [_vm._v(" search ")]
                                  ),
                                  _vm._v(" Filtrar Resultados ")
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            VExpansionPanelContent,
                            [
                              _c(
                                VRow,
                                { staticClass: "my-4" },
                                [
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "mx-2",
                                      attrs: {
                                        cols: "9",
                                        sm: "12",
                                        md: "12",
                                        lg: "2"
                                      }
                                    },
                                    [
                                      _c(VSelect, {
                                        attrs: {
                                          solo: "",
                                          small: "",
                                          items: _vm.formasDePagamento,
                                          label: "Forma de Pagamento",
                                          clearable: "",
                                          "item-text": "label",
                                          "item-value": "value",
                                          "return-object": true
                                        },
                                        model: {
                                          value: _vm.filtros.formaPagamento,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filtros,
                                              "formaPagamento",
                                              $$v
                                            )
                                          },
                                          expression: "filtros.formaPagamento"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    VTooltip,
                                    {
                                      attrs: { right: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                VIcon,
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass: "my-auto",
                                                      attrs: {
                                                        color:
                                                          "grey lighten-2\n"
                                                      }
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v(" help ")]
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "Buscar por Valor Inicial: utilize esse campo para fazer busca a partir do valor inicial.Lembrando"
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "mx-2",
                                      attrs: {
                                        cols: "9",
                                        xs: "12",
                                        sm: "12",
                                        lg: "2"
                                      }
                                    },
                                    [
                                      _c(VSelect, {
                                        attrs: {
                                          solo: "",
                                          small: "",
                                          items: _vm.statusPagamento,
                                          label: "Status do Pagamento",
                                          clearable: "",
                                          "item-text": "label",
                                          "item-value": "value",
                                          "return-object": true
                                        },
                                        model: {
                                          value: _vm.filtros.statusPagamento,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filtros,
                                              "statusPagamento",
                                              $$v
                                            )
                                          },
                                          expression: "filtros.statusPagamento"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    VTooltip,
                                    {
                                      attrs: { right: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                VIcon,
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        color: "grey lighten-2"
                                                      }
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v(" help ")]
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "Buscar por Valor Final: utilize esse campo para fazer busca a partir do valor inicial"
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "mx-2",
                                      attrs: {
                                        cols: "6",
                                        xs: "2",
                                        sm: "2",
                                        md: "3",
                                        lg: "3"
                                      }
                                    },
                                    [
                                      _c(
                                        VMenu,
                                        {
                                          ref: "menu1",
                                          attrs: {
                                            "close-on-content-click": false,
                                            transition: "scale-transition",
                                            "offset-y": "",
                                            "min-width": "290px"
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    VTextField,
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            solo: "",
                                                            label:
                                                              "Última atualização de:",
                                                            "prepend-icon":
                                                              "mdi-calendar",
                                                            readonly: "",
                                                            placeholder:
                                                              "Selecione no Calendário",
                                                            clearable: ""
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.limparDataInicio
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.computedDateFormattedInicio,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.computedDateFormattedInicio = $$v
                                                            },
                                                            expression:
                                                              "\n                                                        computedDateFormattedInicio\n                                                    "
                                                          }
                                                        },
                                                        "v-text-field",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    )
                                                  )
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value: _vm.menu1,
                                            callback: function($$v) {
                                              _vm.menu1 = $$v
                                            },
                                            expression: "menu1"
                                          }
                                        },
                                        [
                                          _c(
                                            VDatePicker,
                                            {
                                              attrs: {
                                                "no-title": "",
                                                scrollable: "",
                                                locale: "pt-br"
                                              },
                                              model: {
                                                value: _vm.filtros.data_inicio,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.filtros,
                                                    "data_inicio",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "filtros.data_inicio"
                                              }
                                            },
                                            [
                                              _c(VSpacer),
                                              _c(
                                                VBtn,
                                                {
                                                  attrs: {
                                                    text: "",
                                                    color: "primary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.menu1 = false
                                                    }
                                                  }
                                                },
                                                [_vm._v(" Cancel ")]
                                              ),
                                              _c(
                                                VBtn,
                                                {
                                                  attrs: {
                                                    text: "",
                                                    color: "primary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.$refs.menu1.save(
                                                        _vm.filtros
                                                          .data_inicio_fmt
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v(" OK ")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    VTooltip,
                                    {
                                      attrs: { left: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                VIcon,
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        color:
                                                          "grey lighten-2\n"
                                                      }
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v(" help ")]
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "Atualização: Selecione a data inicial da renegociação que deseja encontrar, após selecionar clique em OK"
                                        )
                                      ])
                                    ]
                                  ),
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "mx-2",
                                      attrs: {
                                        cols: "6",
                                        xs: "2",
                                        sm: "2",
                                        md: "3",
                                        lg: "3"
                                      }
                                    },
                                    [
                                      _c(
                                        VMenu,
                                        {
                                          ref: "menu2",
                                          attrs: {
                                            "close-on-content-click": false,
                                            transition: "scale-transition",
                                            "offset-y": "",
                                            "min-width": "290px"
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    VTextField,
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            label:
                                                              "Última atualização até:",
                                                            "prepend-icon":
                                                              "mdi-calendar",
                                                            readonly: "",
                                                            placeholder:
                                                              "Selecione no Calendário",
                                                            clearable: "",
                                                            solo: ""
                                                          },
                                                          on: {
                                                            change:
                                                              _vm.limparDataFim
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.computedDateFormattedFim,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.computedDateFormattedFim = $$v
                                                            },
                                                            expression:
                                                              "\n                                                        computedDateFormattedFim\n                                                    "
                                                          }
                                                        },
                                                        "v-text-field",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    )
                                                  )
                                                ]
                                              }
                                            }
                                          ]),
                                          model: {
                                            value: _vm.menu2,
                                            callback: function($$v) {
                                              _vm.menu2 = $$v
                                            },
                                            expression: "menu2"
                                          }
                                        },
                                        [
                                          _c(
                                            VDatePicker,
                                            {
                                              attrs: {
                                                "no-title": "",
                                                scrollable: "",
                                                locale: "pt-br"
                                              },
                                              model: {
                                                value: _vm.filtros.data_fim,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.filtros,
                                                    "data_fim",
                                                    $$v
                                                  )
                                                },
                                                expression: "filtros.data_fim"
                                              }
                                            },
                                            [
                                              _c(VSpacer),
                                              _c(
                                                VBtn,
                                                {
                                                  attrs: {
                                                    text: "",
                                                    color: "primary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.menu2 = false
                                                    }
                                                  }
                                                },
                                                [_vm._v(" Cancel ")]
                                              ),
                                              _c(
                                                VBtn,
                                                {
                                                  attrs: {
                                                    text: "",
                                                    color: "primary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.$refs.menu2.save(
                                                        _vm.filtros.data_fim_fmt
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v(" OK ")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    VTooltip,
                                    {
                                      attrs: { left: "" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                VIcon,
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        color: "grey lighten-2"
                                                      }
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [_vm._v(" help ")]
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          "Atualização: Selecione a data final da renegociação que deseja encontrar, após selecionar clique em OK"
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                VRow,
                                { staticClass: "my-4" },
                                [
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "mx-2",
                                      attrs: {
                                        cols: "9",
                                        sm: "12",
                                        md: "12",
                                        lg: "2"
                                      }
                                    },
                                    [
                                      _c(VSelect, {
                                        attrs: {
                                          solo: "",
                                          small: "",
                                          items: _vm.tipoOferta,
                                          label: "Tipo de Oferta",
                                          clearable: "",
                                          "item-text": "label",
                                          "item-value": "value",
                                          "return-object": true
                                        },
                                        model: {
                                          value: _vm.filtros.tipoOferta,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.filtros,
                                              "tipoOferta",
                                              $$v
                                            )
                                          },
                                          expression: "filtros.tipoOferta"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "px-3 pt-0 pb-3",
                                      attrs: {
                                        cols: "7",
                                        xs: "7",
                                        sm: "4",
                                        md: "4",
                                        lg: "5",
                                        align: "left"
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "font-weight-bold text-h6",
                                          staticStyle: { color: "#6c6c6c" }
                                        },
                                        [
                                          _vm._v(
                                            " Data de atualização do Título "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "font-weight-normal",
                                          staticStyle: { color: "#6c6c6c" }
                                        },
                                        [
                                          _vm._v(
                                            " Através dos atalhos ao lado, selecione os títulos que se encontram dentro prazo de atualização disponível. "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    VCol,
                                    {
                                      staticClass:
                                        "d-flex flex-row justify-center align-center px-3 pt-0 pb-3",
                                      attrs: {
                                        cols: "5",
                                        xs: "5",
                                        sm: "5",
                                        md: "5",
                                        lg: "4",
                                        align: "center"
                                      }
                                    },
                                    [
                                      _c(
                                        VBtn,
                                        {
                                          staticClass: "ma-1",
                                          attrs: { color: "primary" },
                                          on: {
                                            click: function($event) {
                                              return _vm.setDataMovimentoFiltro(
                                                1
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" Até 1D ")]
                                      ),
                                      _c(
                                        VBtn,
                                        {
                                          staticClass: "ma-1",
                                          attrs: { color: "primary" },
                                          on: {
                                            click: function($event) {
                                              return _vm.setDataMovimentoFiltro(
                                                7
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" Até 7D ")]
                                      ),
                                      _c(
                                        VBtn,
                                        {
                                          staticClass: "ma-1",
                                          attrs: { color: "primary" },
                                          on: {
                                            click: function($event) {
                                              return _vm.setDataMovimentoFiltro(
                                                30
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" Até 30D ")]
                                      ),
                                      _c(
                                        VBtn,
                                        {
                                          staticClass: "my-1 mr-0 ml-1",
                                          attrs: { color: "primary" },
                                          on: {
                                            click: function($event) {
                                              return _vm.setDataMovimentoFiltro(
                                                90
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" Até 90D ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            VCard,
            {
              staticClass: "pa-3",
              nativeOn: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.aplicarFiltros.apply(null, arguments)
                }
              }
            },
            [
              _c(
                VRow,
                { staticClass: "my-1" },
                [
                  _c(VCol, {
                    staticClass: "mx-3",
                    attrs: { cols: "4", xs: "2", sm: "2", md: "7", lg: "7" }
                  }),
                  _c(VCol, {
                    staticClass: "mx-2",
                    attrs: {
                      cols: "11",
                      xs: "6",
                      sm: "6",
                      md: "2",
                      lg: "2",
                      align: "center"
                    }
                  }),
                  _c(
                    VCol,
                    {
                      staticClass: "mx-2",
                      attrs: {
                        cols: "11",
                        xs: "6",
                        sm: "6",
                        md: "2",
                        lg: "2",
                        align: "center"
                      }
                    },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "mt-n1 d-flex justify-center",
                          attrs: {
                            width: "180",
                            color: "primary",
                            loading: _vm.loadingFiltros
                          },
                          on: { click: _vm.botaoFiltrar }
                        },
                        [
                          _vm._v(" Buscar "),
                          _c(VIcon, { staticClass: "ml-2" }, [
                            _vm._v("search")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.snackbar
            ? _c("Snackbar", {
                attrs: {
                  mostrarSnackbar: _vm.snackbar,
                  corSnackbar: _vm.snackbarColor,
                  mensagemSnackbar: _vm.mensagemAviso
                },
                on: {
                  fecharSnackbar: function($event) {
                    _vm.snackbar = false
                  }
                }
              })
            : _vm._e(),
          _c(VDataTable, {
            staticClass: "elevation-1 pa-3 mt-3",
            attrs: {
              headers: _vm.headers,
              items: _vm.renegociacoes,
              "item-key": "renegociacoes"
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.id",
                  fn: function(ref) {
                    var item = ref.item
                    return [_c("span", [_vm._v(" " + _vm._s(item.id) + " ")])]
                  }
                },
                {
                  key: "item.status",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.status == "WAITING_PAYMENT"
                        ? _c("span", [_vm._v(" Aguardando Pagamento ")])
                        : _vm._e(),
                      item.status == "DEBT_PAID_OFF"
                        ? _c("span", [_vm._v(" Pagamento Confirmado ")])
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "item.tipo_pagamento",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.tipo_pagamento === "B"
                        ? _c("span", [_vm._v(" Boleto ")])
                        : _vm._e(),
                      item.tipo_pagamento === "C"
                        ? _c("span", [_vm._v(" Cartão ")])
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "item.tipo_oferta",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.tipo_oferta === "A"
                        ? _c("span", [_vm._v(" Oferta Global ")])
                        : _vm._e(),
                      item.tipo_oferta === "T"
                        ? _c("span", [_vm._v(" Oferta Título ")])
                        : _c("span", [
                            _vm._v(" " + _vm._s(item.tipo_oferta) + " ")
                          ])
                    ]
                  }
                },
                {
                  key: "item.updated_at",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.formatDate(item.updated_at)) + " "
                      )
                    ]
                  }
                },
                {
                  key: "item.id_titulo",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        "span",
                        { staticClass: "d-flex" },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "d-flex justify-center align-center"
                            },
                            [_vm._v(" " + _vm._s(item.id_titulo) + " ")]
                          ),
                          _c(
                            VBtn,
                            {
                              staticClass: "ma-3",
                              attrs: {
                                "x-small": "",
                                elevation: "0",
                                fab: "",
                                loading: _vm.loadingFiltros
                              },
                              on: {
                                click: function($event) {
                                  return _vm.exibirDetalheTitulo(item)
                                }
                              }
                            },
                            [_c(VIcon, [_vm._v("mdi-open-in-new")])],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                },
                {
                  key: "item.oferta_origem",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      item.titulo_oferta
                        ? _c(
                            "span",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "d-flex justify-center align-center"
                                },
                                [_vm._v(" " + _vm._s(item.titulo_oferta) + " ")]
                              ),
                              _c(
                                VBtn,
                                {
                                  staticClass: "ma-3",
                                  attrs: {
                                    "x-small": "",
                                    elevation: "0",
                                    fab: "",
                                    loading: _vm.loadingFiltros
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.exibirDetalheOfertaTitulo(item)
                                    }
                                  }
                                },
                                [_c(VIcon, [_vm._v("mdi-open-in-new")])],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      item.apresentante_oferta
                        ? _c(
                            "span",
                            { staticClass: "d-flex" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "d-flex justify-center align-center"
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(item.apresentante_oferta) + " "
                                  )
                                ]
                              ),
                              _c(
                                VBtn,
                                {
                                  staticClass: "ma-3",
                                  attrs: {
                                    "x-small": "",
                                    elevation: "0",
                                    fab: "",
                                    loading: _vm.loadingFiltros
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.exibirDetalheOfertaGlobal(item)
                                    }
                                  }
                                },
                                [_c(VIcon, [_vm._v("mdi-open-in-new")])],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "item.valor",
                  fn: function(ref) {
                    var item = ref.item
                    return [_vm._v(" R$ " + _vm._s(item.valor) + " ")]
                  }
                },
                {
                  key: "item.valor_pago",
                  fn: function(ref) {
                    var item = ref.item
                    return [_vm._v(" R$ " + _vm._s(item.valor_pago) + " ")]
                  }
                },
                {
                  key: "item.cartao",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        VIcon,
                        {
                          style: { color: item.cartao != "" ? "green" : "red" }
                        },
                        [_vm._v(_vm._s(_vm.getBolean(item.cartao)))]
                      )
                    ]
                  }
                },
                {
                  key: "item.juros_cartao",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.formatPorcentagem(item.juros_cartao)) +
                            " "
                        )
                      ])
                    ]
                  }
                },
                {
                  key: "item.boleto",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c(
                        VIcon,
                        {
                          style: { color: item.boleto != "" ? "green" : "red" }
                        },
                        [_vm._v(_vm._s(_vm.getBolean(item.boleto)))]
                      )
                    ]
                  }
                },
                {
                  key: "item.juros_boleto",
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.formatPorcentagem(item.juros_boleto)) +
                            " "
                        )
                      ])
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      ),
      _vm.dialogTitulo
        ? _c(
            VDialog,
            {
              attrs: {
                "max-width": "1200px",
                persistent: "",
                scrollable: "",
                color: "background",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.dialogTitulo,
                callback: function($$v) {
                  _vm.dialogTitulo = $$v
                },
                expression: "dialogTitulo"
              }
            },
            [
              _c(
                VCard,
                [
                  _c(
                    VCardTitle,
                    { staticClass: "pa-0" },
                    [
                      _c(
                        VToolbar,
                        { attrs: { flat: "", dark: "", color: "primary" } },
                        [
                          _c(
                            VBtn,
                            {
                              staticClass: "ml-0",
                              attrs: { icon: "", dark: "" },
                              on: { click: _vm.closeDialog }
                            },
                            [_c(VIcon, [_vm._v("mdi-close")])],
                            1
                          ),
                          _c(VToolbarTitle, [_vm._v("Detalhes do Título")]),
                          _c(VSpacer)
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(VDivider),
                  _c(
                    VCardText,
                    { staticClass: "px-8 py-4" },
                    [
                      _c("DetalheTitulo", {
                        attrs: { titulo: _vm.titulo },
                        on: { fecharModal: _vm.closeDialog }
                      }),
                      _c("HistoricoTitulo", {
                        staticClass: "mt-3",
                        attrs: { historico_titulo: _vm.historicos }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.dialogOfertaTitulo
        ? _c(
            VDialog,
            {
              attrs: {
                "max-width": "1200px",
                persistent: "",
                color: "background",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.dialogOfertaTitulo,
                callback: function($$v) {
                  _vm.dialogOfertaTitulo = $$v
                },
                expression: "dialogOfertaTitulo"
              }
            },
            [
              _c(
                VCard,
                [
                  _c(
                    VToolbar,
                    { attrs: { flat: "", dark: "", color: "primary" } },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: { icon: "", dark: "" },
                          on: { click: _vm.closeDialog }
                        },
                        [_c(VIcon, [_vm._v("mdi-close")])],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "12", sm: "2", align: "left" } },
                        [_c(VToolbarTitle, [_vm._v("Detalhe Oferta")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    VCardText,
                    [
                      _c("DetalheOfertaTitulo", {
                        attrs: {
                          oferta: _vm.oferta,
                          activeIdOffer: _vm.activeIdOffer,
                          setAtualizarTitulo: _vm.setAtualizarTitulo,
                          mostrarConfirmacao: _vm.mostrarConfirmacao,
                          habilitaCorrecao: _vm.habilitaCorrecao
                        },
                        on: {
                          fecharModal: _vm.closeDialog,
                          showSnackbar: _vm.snackbarDetalheTitulo,
                          atualizarTitulo: _vm.atualizarTitulo
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VContainer,
                    [
                      _c(
                        VRow,
                        {
                          staticClass: "pa-2",
                          attrs: { align: "baseline", justify: "end" }
                        },
                        [
                          _vm.oferta.id
                            ? _c(
                                VBtn,
                                {
                                  staticClass: "ml-5",
                                  attrs: {
                                    dense: "",
                                    color: "grey white--text"
                                  },
                                  on: { click: _vm.closeDialog }
                                },
                                [_vm._v(" Fechar ")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.dialogOfertaGlobal
        ? _c(
            VDialog,
            {
              attrs: {
                "max-width": "1200px",
                persistent: "",
                color: "background",
                transition: "dialog-bottom-transition"
              },
              model: {
                value: _vm.dialogOfertaGlobal,
                callback: function($$v) {
                  _vm.dialogOfertaGlobal = $$v
                },
                expression: "dialogOfertaGlobal"
              }
            },
            [
              _c(
                VCard,
                [
                  _c(
                    VToolbar,
                    { attrs: { flat: "", dark: "", color: "primary" } },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: { icon: "", dark: "" },
                          on: { click: _vm.closeDialog }
                        },
                        [_c(VIcon, [_vm._v("mdi-close")])],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "12", sm: "2", align: "left" } },
                        [_c(VToolbarTitle, [_vm._v("Detalhe Oferta")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    VCardText,
                    [
                      _c("DetalheOfertaGlobal", {
                        attrs: {
                          oferta: _vm.oferta,
                          activeIdOffer: _vm.activeIdOffer,
                          setAtualizarTitulo: _vm.setAtualizarTitulo,
                          mostrarConfirmacao: _vm.mostrarConfirmacao,
                          habilitaCorrecao: _vm.habilitaCorrecao
                        },
                        on: {
                          fecharModal: _vm.closeDialog,
                          showSnackbar: _vm.snackbarDetalheTitulo,
                          atualizarTitulo: _vm.atualizarTitulo
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    VContainer,
                    [
                      _c(
                        VRow,
                        {
                          staticClass: "pa-2",
                          attrs: { align: "baseline", justify: "end" }
                        },
                        [
                          _vm.oferta.id
                            ? _c(
                                VBtn,
                                {
                                  staticClass: "ml-5",
                                  attrs: {
                                    dense: "",
                                    color: "grey white--text"
                                  },
                                  on: { click: _vm.closeDialog }
                                },
                                [_vm._v(" Fechar ")]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }